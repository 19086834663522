<template>
  <div>
    <div class="wrap">
      <div class="menus">
        <div class="menu" :class="{ active: isActive == 'task-template' }">
          <div class="num">{{ getNowDate }}</div>
          <div class="tip">{{ date.year }}/{{ getNowMonth }}</div>
        </div>
        <div class="menu" :class="{ active: isActive == 'company-push' }" @click="handleSelect('company-push')">
          <div class="num">{{ detail.zcpp }}</div>
          <div class="tip">政策推送</div>
        </div>
        <div class="menu" :class="{ active: isActive == 'member' }" @click="handleSelect('member')">
          <div class="num">{{ detail.zhbd }}</div>
          <div class="tip">账户绑定</div>
        </div>
      </div>
    </div>
    <div class="main">
      
      <router-view />
    </div>
  </div>
</template>
<script>
import service from '@/service/service'
import { mapActions,mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      isActive: '',
      date: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
      },
      detail:{
        glqy:'0',
        zcpp:'0'
      },
      is_rootpath:0
    }
  },
  computed: {
    ...mapState(['userinfo']),
    getNowDate() {
      return this.date.day < 10 ? ('0' + this.date.day) : this.date.day
    },
    getNowMonth() {
      return this.date.month < 10 ? ('0' + this.date.month) : this.date.month
    }
  },
  unmounted(){
    clearInterval(this.inviteIndex);
  },
  async created() {
    await this.get_qrcode_info();
    // console.log("====>")
    // console.log(this.$route)
    if(this.$route.name == "WorkHome"){
      this.is_rootpath = 1
    }
    this.getNum();
    this.inviteIndex = setInterval(this.getNum, 60000)
  },
  methods: {
    ...mapActions(['getUserinfo']),
    ///扫码登录的方法
    async get_qrcode_info(){
      //console.log(this.userinfo)
      if(!this.userinfo){
        let res = await service.get_qrcode_user()
        if(res.code == 1){
          window.sessionStorage.setItem('token', res.data.token);
        }
        this.getUserinfo()

      }
      
    },

    goCompany(){
      this.is_rootpath = 0;
      this.$router.push('/work/home/company?addcompany=1')
    },

    async getNum() {
      let res = await service.get_gzt_num();
      // console.log(res);
      this.detail = res.data;
    },
    
    handleSelect(key) {
      this.isActive = key;
      if(key == "member"){
        key = key + "?tab=subaccount"
      }
      this.$router.push('/work/home/' + key)
    },
  }
}
</script>
<style lang="less" scoped>
.wrap {
  padding: 24px 0;
  overflow: hidden;
  .menus {
    width: 1400px;
    margin:auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    .menu {
      width: 100px;
      margin-right:20px;
      height: 100px;
      border-radius: 10px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      box-shadow: 0 0 16px rgba(51, 135, 255, .12);

      &.active {
        &::after {
          display: block;
          content: ' ';
          width: 100%;
          height: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: @primary-color;
        }

        .tip {
          color: @primary-color;
        }
      }

      .num {
        font-size: 40px;
        color: @primary-color;
        height: 40px;
        line-height: 40px;
      }

      .icon {
        height: 38px;
      }

      .tip {
        margin-top: 6px;
        color: @text-color;
        font-size: 18px;
        font-weight: 100;
      }
    }
  }
  
}
.main {
    width:1400px;
    margin:auto;
  }
</style>